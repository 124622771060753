<template>
  <div class="container">
    <nav class="menu">
      <div class="logo">
        <div class="wrapper">
          <img :src="logo" alt="logo">
        </div>
      </div>
      <ul>
        <li
          v-for="(item, index) in menu"
          :key="item.value"
          :class="{ active: index === activeIndex }"
          @click="switchMenu(index)"
        >{{ item.label }}</li>
      </ul>
    </nav>
    <div class="main">
      <div class="title">
        <h1>{{ currentItem.label }}</h1>
      </div>
      <template v-if="contents">
        <div class="content" v-html="contents[currentItem.value]" />
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutUs',
  data() {
    return {
      contents: null,
      activeIndex: 0,
      menu: [{
        label: '关于我们',
        value: 'about',
      }, {
        label: '联系我们',
        value: 'contactUs',
      }, {
        label: '免责声明',
        value: 'disclaimer',
      }],
      logo: require('assets/images/logo_about.png'),
    }
  },
  computed: {
    currentItem() {
      return this.menu[this.activeIndex]
    }
  },
  methods: {
    switchMenu(index) {
      this.activeIndex = index
    },
    async getContents() {
      try {
        const res = await this.$http.get('/api/v1/sysconfig/get')
        this.contents = res.returnvalue
        this.showMenu()
      } catch (e) {
        console.log(e)
      }
    },
    showMenu() {
      const type = this.$route.query.type
      if (!type) return
      const index = this.menu.findIndex(item => item.value === type)
      if (index > -1) {
        this.switchMenu(index)
      }
    }
  },
  mounted() {
    this.getContents()
  }
}
</script>

<style lang="scss" scoped>
  .container {
    display: flex;
    nav.menu {
      position: fixed;
      width: 280px;
      min-width: 280px;
      height: 100%;
      border-right: 1px solid #e6e6e6;
      .logo {
        padding: 0 30px;
        .wrapper {
          border-bottom: 1px solid #e6e6e6;
          padding: 30px 0;
        }
      }
      ul {
        padding: 30px 0;
        li {
          margin-bottom: 5px;
          padding: 10px 30px;
          // height: 40px;
          cursor: pointer;
          &.active, &:hover {
            background-color: #E9F3FF;
            color: #2985FD;
            border-right: 2px solid #2985FD;
          }
        }
      }
    }
    .main {
      margin-left: 280px;
      padding: 30px;
      width: 100%;
      .title {
        width: 100%;
        padding-bottom: 30px;
        border-bottom: 1px solid #e6e6e6;
        h1 {
          margin: 0;
          font-size: 24px;
          line-height: 28px;
          font-weight: normal;
          color: #333;
        }
      }
      .content {
        padding-top: 30px;
        overflow: hidden;
        word-break: break-all;
        line-height: normal;
        white-space: pre-wrap;
        /deep/ img {
          width: 560px;
        }
      }
    }
  }
</style>
